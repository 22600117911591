import classNames from 'classnames';
import React from 'react';

type Value = string | number;

interface Props {
  value?: Value;
  onChange: (value: Value) => void;
  options: { label: string; value: Value; disabled?: boolean }[];
  className?: string;
  selectedClassName?: string;
}

const ButtonGroup: React.FC<Props> = ({ value, options, onChange, className, selectedClassName }) => (
  <span className="isolate flex rounded-md">
    {options.map((opt) => (
      <button
        key={opt.value}
        onClick={() => onChange(opt.value)}
        type="button"
        disabled={opt.disabled}
        className={classNames(
          'relative inline-flex items-center px-5 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 first:rounded-l-full last:rounded-r-full focus:z-10',
          className,
          {
            'bg-primary-100': opt.value === value,
            'bg-white hover:bg-gray-100': opt.value !== value,
            'hover:none bg-gray-500 text-gray-400 opacity-40': opt.disabled,
          },
          selectedClassName && {
            [selectedClassName]: opt.value === value,
          }
        )}
      >
        {opt.label}
      </button>
    ))}
  </span>
);

export default ButtonGroup;
