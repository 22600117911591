import { DeleteConfirmationModal, Dropdowns, MUIcon, Table } from '@platform/shared/ui';
import { PeTypes } from '@platform/types';
import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import { useSessionContext } from '../../../../contexts/SessionContext';
import * as peApi from '../../../../pe.api';
import EditColumnModal from '../EditColumnModal';
import DuplicateColumnModal from './DuplicateColumnModal';

const enum ACTION {
  EDIT,
  DUPLICATE,
  DELETE,
  SORT_DESC,
  SORT_ASC,
}

const enum DataType {
  DOUBLE = 2,
}

interface Props {
  datasetId: string;
  column: Table.Column;
  title: string;
  label: string;
  formatting: string;
  type: number;
  columnTitles: string[];
}

const DatasetColumnMenu: React.FC<Props> = ({ datasetId, column, title, columnTitles, label, type, formatting }) => {
  const { notify } = useSessionContext();
  const menuRef = useRef<HTMLDivElement>(null);
  const menuIconRef = useRef<HTMLDivElement>(null);
  const [currentAction, setCurrentAction] = useState<ACTION | null>(null);

  const OPTIONS: Dropdowns.DropdownOption[] = [
    { name: 'Edit', value: ACTION.EDIT, additionalStyles: 'text-xs px-3', icon: <MUIcon name="edit" /> },
    {
      name: 'Sort',
      value: [
        {
          name: `Sort ${type === DataType.DOUBLE ? '1 to 9' : 'A to Z'}`,
          value: ACTION.SORT_ASC,
          additionalStyles: 'text-xs px-3',
        },
        {
          name: `Sort ${type === DataType.DOUBLE ? '9 to 1' : 'Z to A'}`,
          value: ACTION.SORT_DESC,
          additionalStyles: 'text-xs px-3',
        },
      ],
      additionalStyles: 'text-xs px-3 border-b',
      icon: <MUIcon name="sort_by_alpha" />,
      suffixIcon: <MUIcon name="arrow_right" />,
    },
    {
      name: 'Duplicate column',
      value: ACTION.DUPLICATE,
      additionalStyles: 'text-xs px-3',
      icon: <MUIcon name="content_copy" />,
    },
    { name: 'Delete column', value: ACTION.DELETE, additionalStyles: 'text-xs px-3', icon: <MUIcon name="delete" /> },
  ];

  const deleteColumnMutation = useMutation({
    mutationFn: () => peApi.deleteColumn(datasetId, column.id),
    onSuccess: () => {
      setCurrentAction(null);
    },
  });

  const editColumnMetadataMutation = useMutation({
    mutationFn: (payload: PeTypes.EditColumnMetadataRequest) => peApi.editColumnMetadata(datasetId, column.id, payload),
    onError: async () => {
      notify({
        hideAfterSec: 2,
        content: 'Failed to update column.',
      });
    },
  });

  const duplicateColumnMutation = useMutation({
    mutationFn: (payload: { columnName: string; label: string }) =>
      peApi.duplicateColumn(datasetId, { columnUuid: column.id, ...payload }),
  });

  useEffect(() => {
    if (!column.onSort) return;
    if (currentAction === ACTION.SORT_DESC) {
      column.onSort(Table.SortOrderDirection.DESC);
    }
    if (currentAction === ACTION.SORT_ASC) {
      column.onSort(Table.SortOrderDirection.ASC);
    }
  }, [currentAction, column]);

  return (
    <>
      <div ref={menuRef}>
        <Dropdowns.Dropdown
          icon={
            <div ref={menuIconRef} className="h-[16px]">
              <MUIcon name="more_vert" className="!text-[16px] text-gray-600" />
            </div>
          }
          iconClose={null}
          options={OPTIONS}
          hAlignment={'right'}
          onSelect={(opt: Dropdowns.DropdownOption) => setCurrentAction(opt.value as ACTION)}
          triggerClasses={classNames(
            'ring-0 shadow-none rounded-full -mr-[10px] w-[28px] h-[28px] flex justify-center'
          )}
          menuClasses="min-w-[150px]"
        />
      </div>
      {currentAction === ACTION.EDIT && (
        <EditColumnModal
          onCloseRequest={() => setCurrentAction(null)}
          title={title}
          formatting={formatting}
          label={label}
          type={type}
          columnTitles={columnTitles}
          onDone={(newValues: PeTypes.EditColumnMetadataRequest) => editColumnMetadataMutation.mutate(newValues)}
        />
      )}
      {currentAction === ACTION.DUPLICATE && (
        <DuplicateColumnModal
          onCloseRequest={() => setCurrentAction(null)}
          columnTitles={columnTitles}
          okLabel="Duplicate"
          onDone={(columnName: string, label: string) => duplicateColumnMutation.mutate({ columnName, label })}
        />
      )}
      {currentAction === ACTION.DELETE && (
        <DeleteConfirmationModal
          onCloseRequest={() => setCurrentAction(null)}
          title={`Delete column ${column.title}`}
          message={`Are you sure you want to delete this column? All data will be permanently removed. This action cannot be undone.`}
          onConfirm={() => deleteColumnMutation.mutate()}
        />
      )}
    </>
  );
};

export default DatasetColumnMenu;
