import { ProjectTypes } from '@platform/types';
import { AxiosError } from 'axios';
import classNames from 'classnames';
import React, { useMemo, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { Primary } from '../Buttons';
import { Input } from '../Inputs';
import MUIcon from '../MUIcon';

interface Props {
  loginMethod: (payload: ProjectTypes.LoginPayload) => any;
  onLoginSuccess: (token: string) => void;
  additionalChildern?: React.ReactNode;
}

type Inputs = {
  email: string;
  password: string;
};

const LoginForm: React.FC<Props> = ({ loginMethod, onLoginSuccess, additionalChildern }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from || '/';
  const loginMutation = useMutation({
    mutationFn: (payload: ProjectTypes.LoginPayload) => loginMethod(payload),
    onSuccess: (token: string) => {
      handleLoginSuccess(token);
    },
  });

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<Inputs>({ defaultValues: { email: '', password: '' } });
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);
  const watchedFormData = watch();
  const onSubmit: SubmitHandler<Inputs> = (data) => loginMutation.mutate(data);
  const hasError = loginMutation.isError;
  const errorMessage: string = useMemo(() => {
    if (loginMutation.error) {
      const error = loginMutation.error as AxiosError;
      return `${error.response?.data}` || 'Something went wrong. Please try again.';
    }
    return '';
  }, [loginMutation.error]);

  const handleLoginSuccess = (token: string) => {
    onLoginSuccess(token);
    navigate(from, { replace: true });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-8" data-cy="login-form">
      {hasError && (
        <div className="w-full bg-red-50 py-3 px-10 text-xs text-red-700" data-cy="error-message">
          {errorMessage}
        </div>
      )}
      <div className="px-10">
        <label
          htmlFor="email"
          className={classNames('mb-2 block text-xs font-semibold text-gray-800', { 'text-red-700': hasError })}
        >
          Email
        </label>
        <Controller
          control={control}
          name="email"
          rules={{ required: 'Email is required' }}
          render={({ field }) => (
            <Input
              type="email"
              classes={classNames('rounded-lg h-[42px]', { 'ring-red-700': hasError })}
              id="email"
              placeholder="Email"
              data-cy="email-input"
              value={field.value}
              onChange={(e) => field.onChange(e.target.value)}
            />
          )}
        />

        {errors.email && <span className="text-red-400">{errors.email.message}</span>}
      </div>
      <div className="px-10">
        <label
          htmlFor="password"
          className={classNames('mb-2 block text-xs font-semibold text-gray-800', { 'text-red-700': hasError })}
        >
          Password
        </label>
        <Controller
          control={control}
          name="password"
          rules={{ required: 'Password is required' }}
          render={({ field }) => (
            <Input
              classes={classNames('rounded-lg h-[42px]', { 'ring-red-700': hasError })}
              type={passwordVisible ? 'text' : 'password'}
              id="password"
              data-cy="password-input"
              placeholder={'Password'}
              value={field.value}
              onChange={(e) => field.onChange(e.target.value)}
              suffixComponent={
                <button
                  type="button"
                  className="flex items-center justify-center"
                  onClick={() => setPasswordVisible(!passwordVisible)}
                  disabled={!watchedFormData.password.length}
                  data-rh={passwordVisible ? 'Hide password' : 'Show password'}
                >
                  <MUIcon
                    name={passwordVisible ? 'visibility_off' : 'visibility'}
                    className={classNames({
                      'text-gray-400': watchedFormData.password.length === 0,
                      'text-gray-600': watchedFormData.password.length > 0,
                    })}
                  />
                </button>
              }
            />
          )}
        />
        {errors.password && <span className="text-red-400">{errors.password.message}</span>}
        {additionalChildern}
      </div>
      <div className="w-full items-center justify-center px-10">
        <Primary
          type="submit"
          data-cy="submit-button"
          className="h-[40px] w-full"
          disabled={!watchedFormData.email.length || !watchedFormData.password.length || loginMutation.isLoading}
        >
          Log In
        </Primary>
      </div>
    </form>
  );
};

export default LoginForm;
