import { RuleGroupType } from 'react-querybuilder';

export enum DataType {
  INTEGER = 'INTEGER',
  DOUBLE = 'DOUBLE',
  TEXT = 'VARCHAR',
  BOOLEAN = 'BOOLEAN',
}

export interface Column {
  name: string;
  title: string;
  type: 'BOOLEAN' | 'VARCHAR' | 'INTEGER' | 'DOUBLE';
  allowFilter: boolean;
  metadata: {
    values: string[];
  };
}

export interface Data {
  data: Record<string, string>[];
}

export interface CountDatasetRowsRequest {
  dataQuery?: RuleGroupType;
}

export interface Metadata {
  name: string;
  datasets: Dataset[];
}

export interface Dataset {
  name: string;
  recordLabel: string;
  variables: Variable[];
}

export interface Variable {
  name: string;
  label: string;
  dataType: string;
  sqlDataType: 'BOOLEAN' | 'VARCHAR' | 'INTEGER' | 'DOUBLE';
  formatting: string;
  showInFiltersList: boolean;
  showInSummarizeByList: boolean;
  showTopValuesByFrequency: boolean;
  descriptiveStats: DescriptiveStats;
  valueLabels: any[];
}

export interface DescriptiveStats {
  nullValueCount: number;
  nonNullValueCount: number;
  uniqueValueCount: number;
  mode: string;
  mean?: number;
  median?: number;
  minimum?: number;
  maximum?: number;
  range?: number;
  variance?: number;
  standardDeviation?: number;
  firstQuartile?: number;
  thirdQuartile?: number;
  interQuartileRange?: number;
  topValuesByFrequency: TopValuesByFrequency[];
}

export interface TopValuesByFrequency {
  value: string;
  count: number;
}

export interface SummarizeRequest {
  spec: SummarizePayload;
  dataQuery?: RuleGroupType;
}

export interface SummarizeResult {
  data: [Record<string, string>];
  durationInMs: number;
  metadata?: SummarizationMetadata;
}

export interface SummarizeTable {
  name: string;
  title: string;
  displayName?: string;
  summarize?: unknown;
  variables?: {
    name: string;
    label: string;
    indent: string;
    formatting: string;
    valueSql: string;
  }[];
}

export interface SummarizePayload {
  universe?: {
    filter: string;
    label?: string;
  };
  geoFilter?: {
    value: string;
  };
  groupBy?: {
    value: string;
  };
  orderBy?: {
    value: string;
  };
  tables?: SummarizeTable[];
}

export interface DownloadAbacusDataRequest {
  columnNames: string[];
  name?: string;
  dataQuery?: RuleGroupType;
}

export interface DataRequest {
  columnNames: string[];
  limit?: number;
  offset?: number;
  whereClause?: string;
}

export interface AbacusInputMetadata {
  Name: string;
  Datasets: [
    {
      Name: string;
      RecordLabel: string;
      Variables: {
        Name: string;
        ShowInFiltersList: boolean;
        Format?: string;
        Label?: string;
      }[];
    }
  ];
}

export interface SummarizationMetadata {
  Name: string;
  DisplayName: string | null;
  Publisher: string | null;
  Guid: string | null;
  Year: number | null;
  YearFrom: number | null;
  YearTo: number | null;
  Priority: number;
  ProductTags: string | null;
  Notes: string | null;
  HasAnyDocumentation: boolean;
  DescriptionShort: string | null;
  Description: string | null;
  Datasets: {
    Name: string;
    GUID: string | null;
    DisplayName: string | null;
    ProductTags: string | null;
    Source: string | null;
    Abbreviation: string;
    Notes: string | null;
    Publisher: string | null;
    Partial: boolean;
    SurveyName: string;
    TableCount: number;
    Description: string | null;
    DescriptionShort: string | null;
    DocLinks: any[];
    DataCategories: any[];
    Tables: {
      Name: string;
      GUID: string;
      Title: string;
      TitleShort: string | null;
      DefaultFilterSetName: string;
      TableMapInfo: any | null;
      PercentBaseMin: number | null;
      CategoryPriorityOrder: number;
      SuggestedColorPaletteType: string | null;
      SuggestedColorPaletteInverse: string | null;
      SuggestedColorPaletteName: string | null;
      ShowOnFirstPageOfCategoryListing: boolean;
      SideBySideTitle: string | null;
      OriginalTitle: string;
      DisplayName: string;
      SurveyName: string;
      DatasetName: string;
      Notes: string | null;
      Universe: string;
      DatasetAbbreviation: string;
      DataCategoriesAsString: string;
      ProductTags: string;
      DollarYear: number;
      VisibleInMaps: boolean;
      VariableCount: number;
      DocLinks: any[];
      Variables: {
        SuggestedColorPaletteName: string | null;
        SuggestedColorPaletteInverse: string | null;
        SuggestedColorPaletteType: string | null;
        FilterRule: string | null;
        MapTooltipCustom: string | null;
        MapInfoButtonHtmlText: string | null;
        BubbleSizeHint: string;
        DataTypeAsString: string;
        VarType: string;
        GUID: string;
        Name: string;
        Label: string;
        QLabel: string;
        Indent: number;
        Formatting: string;
        PrefVizType: string | null;
        PrefVizValType: string | null;
        Notes: string;
        OriginalLabel: string;
        LabelNoBrakes: string;
        TableGuid: string;
        AggregationMethod: string;
        ParentVariableGuid: string | null;
        PercentBaseUniverseVarIndex: number;
        DocLinks: any[];
        Jsf: string;
      }[];
      OutputColumns: {
        OutputColumns: any[];
      };
    }[];
  }[];
  SystemReports: null;
  GeoTypes: any[];
}
