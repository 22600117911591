import { DeleteConfirmationModal, Dropdowns, MUIcon } from '@platform/shared/ui';
import { PeTypes, ProjectTypes } from '@platform/types';
import React, { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';
import { useWorkspaceContext } from '../../contexts/WorkspaceContext';
import { PROJECT_DROPDOWN_OPTIONS } from '../../enums/dropdown';
import { generateLink } from '../../LinkGenerator';
import * as peApi from '../../pe.api';
import RenameProjectModal from './RenameProjectModal';

interface Props {
  project: PeTypes.RecentProject;
}

const RecentProjectItem: React.FC<Props> = ({ project }) => {
  const queryClient = useQueryClient();
  const [requestedAction, setRequestedAction] = useState<ProjectTypes.MenuActions | null>(null);
  const { activeWorkspace } = useWorkspaceContext();

  const handleSelectDropdownOption = (opt: Dropdowns.DropdownOption) => {
    switch (opt.value) {
      case ProjectTypes.MenuActions.DELETE:
        setRequestedAction(ProjectTypes.MenuActions.DELETE);
        break;
      case ProjectTypes.MenuActions.RENAME:
        setRequestedAction(ProjectTypes.MenuActions.RENAME);
        break;
      default:
        return;
    }
  };

  const deleteProjectMutation = useMutation({
    mutationFn: async () => peApi.softDeleteProject(project.id),
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: ['recent-projects', activeWorkspace.id] });
      queryClient.invalidateQueries({ queryKey: ['recent-stories', activeWorkspace.id] });
      queryClient.invalidateQueries({ queryKey: ['projects', activeWorkspace.id] });
      queryClient.invalidateQueries({ queryKey: ['stories', activeWorkspace.id] });
      queryClient.invalidateQueries({ queryKey: ['profiles', activeWorkspace.id] });
    },
  });

  const onDeleteProject = () => {
    deleteProjectMutation.mutate();
    setRequestedAction(null);
  };
  const descriptorText = `${project.profileCount} ${project.profileCount === 1 ? 'Map' : 'Maps'}, ${
    project.storyCount
  } ${project.storyCount === 1 ? 'Story' : 'Stories'}`;

  return (
    <>
      <Link
        to={generateLink('projects', { workspaceId: activeWorkspace.id })}
        className="group relative flex max-h-[305px] min-h-[185px] min-w-[24.53%] max-w-[105px] flex-1 flex-grow flex-col rounded-[16px] border bg-white shadow hover:shadow-lg"
      >
        <div className="group flex flex-shrink-0 flex-col items-center gap-2 rounded-md rounded">
          <div className="bg-primary-50 flex w-full items-center justify-between rounded-t-[16px] px-4 py-6 text-base text-gray-800">
            <div className="truncate font-bold">{project.title}</div>
            <div className="flex items-center" onClick={(e) => e.preventDefault()} data-cy="recent-projects-options">
              <Dropdowns.Dropdown
                icon={<MUIcon name="more_horiz" className="text-gray-600" />}
                iconClose={null}
                triggerClasses={'rounded-full'}
                options={PROJECT_DROPDOWN_OPTIONS}
                onSelect={handleSelectDropdownOption}
                hAlignment="right"
              />
            </div>
          </div>
          <div className="mt-2 flex w-full items-start truncate px-4 text-base">
            <span className="text-sm text-gray-500">{descriptorText}</span>
          </div>
          <div className="group-hover:text-primary-600 mt-6 mb-4 flex w-full justify-between px-4 text-sm font-semibold text-gray-600">
            <span className="flex font-semibold">Open</span>
            <MUIcon name="arrow_forward" className="group-hover:text-primary-600 m-0 mr-2 flex h-5 w-5 text-gray-600" />
          </div>
        </div>
      </Link>
      {requestedAction === ProjectTypes.MenuActions.DELETE && (
        <DeleteConfirmationModal
          title="Delete project"
          message={`Are you sure you want to delete this project? All data will be permanently removed. This action cannot be undone.`}
          onConfirm={onDeleteProject}
          onCloseRequest={() => setRequestedAction(null)}
        />
      )}
      {requestedAction === ProjectTypes.MenuActions.RENAME && project && (
        <RenameProjectModal
          isRecentProject={true}
          project={project}
          onCloseRequest={() => {
            setRequestedAction(null);
          }}
        />
      )}
    </>
  );
};

export default RecentProjectItem;
