import React, { useState } from 'react';
import { Buttons } from '../..';
import MUIcon from '../../MUIcon';

export interface Props {
  title?: string;
  children: React.ReactNode;
}

const CollapsibleLegendWrapper: React.FC<Props> = ({ title = 'Legend', children }) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(true);

  const expandedContent = (
    <div className="flex flex-col">
      <div className="flex items-center justify-between border-b border-gray-200 py-2 pl-4 pr-2">
        <span className="text-sm font-semibold text-gray-700">{title}</span>
        <Buttons.Secondary
          size="xs"
          className="border-none text-gray-600 shadow-none ring-0"
          onClick={() => setIsExpanded(false)}
          icon={<MUIcon name="close" />}
        />
      </div>
      {children}
    </div>
  );

  const collapsedContent = (
    <div
      className="flex cursor-pointer items-center justify-between gap-2 rounded-lg px-3 py-2 hover:bg-gray-100"
      onClick={() => setIsExpanded(true)}
    >
      <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.5 5C1.5 2.79086 3.29086 1 5.5 1H9.5V9H1.5V5Z" fill="#8E89DE" />
        <path d="M9.5 1H13.5C15.7091 1 17.5 2.79086 17.5 5V9H9.5V1Z" fill="#EFAF66" />
        <path d="M1.5 9H9.5V17H5.5C3.29086 17 1.5 15.2091 1.5 13V9Z" fill="#90AFAA" />
        <path d="M9.5 9H17.5V13C17.5 15.2091 15.7091 17 13.5 17H9.5V9Z" fill="#F1D1AC" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.5 0H13.5C16.2614 0 18.5 2.23858 18.5 5V13C18.5 15.7614 16.2614 18 13.5 18H5.5C2.73858 18 0.5 15.7614 0.5 13V5C0.5 2.23858 2.73858 0 5.5 0ZM5.5 1C3.29086 1 1.5 2.79086 1.5 5V13C1.5 15.2091 3.29086 17 5.5 17H13.5C15.7091 17 17.5 15.2091 17.5 13V5C17.5 2.79086 15.7091 1 13.5 1H5.5Z"
          fill="#E5E7EB"
        />
      </svg>
      <span className="text-sm font-semibold text-gray-700">{title}</span>
      <MUIcon name="keyboard_double_arrow_up" className="text-gray-500" />
    </div>
  );

  return <div className="rounded-lg bg-white shadow-md">{isExpanded ? expandedContent : collapsedContent}</div>;
};

export default CollapsibleLegendWrapper;
