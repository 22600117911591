import { DeleteConfirmationModal, MUIcon } from '@platform/shared/ui';
import { PeTypes, ProjectTypes } from '@platform/types';
import React, { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useWorkspaceContext } from '../../contexts/WorkspaceContext';
import useProject from '../../hooks/useProject';
import { generateLink } from '../../LinkGenerator';
import * as peApi from '../../pe.api';
import ExportModal from '../Export/ExportModal';
import ShareAssetModal from '../ShareAssetModal';
import MenuOptionsWrapper from '../shared/MenuOptionsWrapper';
import CreateStoryModal from '../Stories/CreateStoryModal';
import CopyProfileModal from './CopyProfileModal';

const menuOptions: { title: string; icon: JSX.Element; action: ProjectTypes.MenuActions }[] = [
  {
    title: 'Make a copy',
    icon: <MUIcon name="content_copy" />,
    action: ProjectTypes.MenuActions.COPY,
  },
  {
    title: 'Share',
    icon: <MUIcon name="share" />,
    action: ProjectTypes.MenuActions.SHARE,
  },
  {
    title: 'Export as image',
    icon: <MUIcon name="download" />,
    action: ProjectTypes.MenuActions.EXPORT,
  },
  {
    title: 'Delete',
    icon: <MUIcon name="delete" />,
    action: ProjectTypes.MenuActions.DELETE,
  },
];

interface Props {
  profile: PeTypes.Profile;
}

const ProfileMenu: React.FC<Props> = ({ profile }) => {
  const navigate = useNavigate();
  const { activeWorkspace } = useWorkspaceContext();
  const { data: activeProject } = useProject(profile.projectId ?? undefined);
  const [requestedAction, setRequestedAction] = useState<ProjectTypes.MenuActions | null>(null);
  const queryClient = useQueryClient();

  const profileMutation = useMutation({
    mutationFn: () => peApi.watchProfile(profile.id, !profile.watched),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['profiles', activeWorkspace.id] });
      queryClient.invalidateQueries({ queryKey: ['profile', profile.id] });
    },
  });

  const deleteProfileMutation = useMutation({
    mutationFn: async () => peApi.softDeleteProfile(profile.id),
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: ['profiles', activeWorkspace.id] });
      navigate(
        generateLink(profile.projectId ? 'project' : 'projects', {
          workspaceId: activeWorkspace.id,
          ...(profile.projectId && { projectId: profile.projectId }),
        })
      );
    },
  });

  const onDeleteProfile = () => {
    deleteProfileMutation.mutate();
    setRequestedAction(null);
  };

  const handleClick = (action: ProjectTypes.MenuActions) => {
    if (action === ProjectTypes.MenuActions.WATCH) {
      profileMutation.mutate();
      return;
    }
    setRequestedAction(action);
  };

  const profileWatch = profile.watched ? (
    <div className="flex w-full gap-5 px-6 py-4 hover:bg-gray-100">
      <MUIcon name="bookmark_remove" />
      <span className="text-sm text-gray-900">Remove from watchlist</span>
    </div>
  ) : (
    <div className="flex w-full gap-5 px-6 py-4 hover:bg-gray-100">
      <MUIcon name="bookmark" />
      <span className="text-sm text-gray-900">Add to watchlist</span>
    </div>
  );

  return (
    <>
      <MenuOptionsWrapper projectId={profile.projectId}>
        <button
          className="flex w-full cursor-pointer gap-5 px-6 py-3 hover:bg-gray-100"
          onClick={() => handleClick(ProjectTypes.MenuActions.CREATE_STORY)}
        >
          <MUIcon className="bg-acai rounded-full text-white" name="note_stack" />
          <span className="text-sm text-gray-900">Create a Story</span>
        </button>
        <div className="inset-0 w-full py-4">
          <div className="absolute left-0 right-0 h-[1px] bg-gray-200"></div>
        </div>
        <div className="flex cursor-pointer flex-col">
          <button className="flex cursor-pointer" onClick={() => handleClick(ProjectTypes.MenuActions.WATCH)}>
            {profileWatch}
          </button>
          {menuOptions.map((option) => (
            <button
              key={option.title}
              onClick={() => handleClick(option.action)}
              className="flex w-full cursor-pointer items-center gap-5 py-3 px-6 hover:bg-gray-100"
            >
              {option.icon}
              <span className="text-sm text-gray-900">{option.title}</span>
            </button>
          ))}
        </div>
      </MenuOptionsWrapper>
      {requestedAction === ProjectTypes.MenuActions.SHARE && (
        <ShareAssetModal
          urlToShare={`${window.location.origin}${generateLink('map', {
            workspaceId: activeWorkspace.id,
            mapId: profile.id,
            mode: 'public',
          })}`}
          onCloseRequest={() => {
            setRequestedAction(null);
          }}
        />
      )}
      {requestedAction === ProjectTypes.MenuActions.COPY && (
        <CopyProfileModal
          profileId={profile.id}
          profileTitle={profile.title}
          activeProjectId={activeProject?.id}
          onCloseRequest={() => setRequestedAction(null)}
        />
      )}
      {requestedAction === ProjectTypes.MenuActions.EXPORT && (
        <ExportModal
          assetId={profile.id}
          onCloseRequest={() => {
            setRequestedAction(null);
          }}
          exporting="profile"
        />
      )}
      {requestedAction === ProjectTypes.MenuActions.CREATE_STORY && (
        <CreateStoryModal
          onCloseRequest={() => setRequestedAction(null)}
          defaultProjectId={profile.projectId}
          mapContextId={profile.mapContextId}
        />
      )}
      {requestedAction === ProjectTypes.MenuActions.DELETE && (
        <DeleteConfirmationModal
          title="Delete map"
          message={`Are you sure you want to delete this map? All data will be permanently removed. This action cannot be undone.`}
          onConfirm={onDeleteProfile}
          onCloseRequest={() => setRequestedAction(null)}
        />
      )}
    </>
  );
};

export default ProfileMenu;
