import { DeleteConfirmationModal, MUIcon } from '@platform/shared/ui';
import { PeTypes, ProjectTypes } from '@platform/types';
import React, { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useWorkspaceContext } from '../../../contexts/WorkspaceContext';
import { generateLink } from '../../../LinkGenerator';
import * as peApi from '../../../pe.api';
import ExportModal from '../../Export/ExportModal';
import ShareAssetModal from '../../ShareAssetModal';
import MenuOptionsWrapper from '../../shared/MenuOptionsWrapper';
import CopyStoryModal from '../CopyStoryModal';

const menuOptions: { title: string; icon: JSX.Element; action: ProjectTypes.MenuActions }[] = [
  {
    title: 'Share',
    icon: <MUIcon name="share" />,
    action: ProjectTypes.MenuActions.SHARE,
  },
  {
    title: 'Make a copy',
    icon: <MUIcon name="content_copy" />,
    action: ProjectTypes.MenuActions.COPY,
  },
  {
    title: 'Export as image',
    icon: <MUIcon name="download" />,
    action: ProjectTypes.MenuActions.EXPORT,
  },
  {
    title: 'Delete',
    icon: <MUIcon name="delete" />,
    action: ProjectTypes.MenuActions.DELETE,
  },
];

interface Props {
  story: PeTypes.Story;
}

const StoryMenu: React.FC<Props> = ({ story }) => {
  const [requestedAction, setRequestedAction] = useState<ProjectTypes.MenuActions | null>(null);
  const queryClient = useQueryClient();
  const { activeWorkspace } = useWorkspaceContext();
  const navigate = useNavigate();

  const deleteStoryMutation = useMutation({
    mutationFn: async () => peApi.softDeleteStory(story.id),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['story', story.id] });
      await queryClient.invalidateQueries({ queryKey: ['stories', activeWorkspace.id] });
      navigate(
        generateLink(story.projectId ? 'project' : 'projects', {
          workspaceId: activeWorkspace.id,
          ...(story.projectId && { projectId: story.projectId }),
        })
      );
    },
  });

  const onDeleteStory = () => {
    deleteStoryMutation.mutate();
    setRequestedAction(null);
  };

  return (
    <>
      <MenuOptionsWrapper projectId={story.projectId}>
        <div className="flex cursor-pointer flex-col">
          {menuOptions.map((option) => (
            <div
              key={option.title}
              onClick={() => setRequestedAction(option.action)}
              className="flex w-full cursor-pointer items-center gap-5 py-4 px-6 hover:bg-gray-100"
            >
              {option.icon}
              <span className="text-sm text-gray-900">{option.title}</span>
            </div>
          ))}
        </div>
      </MenuOptionsWrapper>

      {requestedAction === ProjectTypes.MenuActions.SHARE && (
        <ShareAssetModal
          urlToShare={`${window.location.origin}${generateLink('story', {
            workspaceId: activeWorkspace.id,
            storyId: story.id,
            mode: 'public',
          })}`}
          onCloseRequest={() => {
            setRequestedAction(null);
          }}
        />
      )}

      {requestedAction === ProjectTypes.MenuActions.COPY && (
        <CopyStoryModal
          storyId={story.id}
          storyTitle={story.title}
          activeProjectId={story.projectId}
          onCloseRequest={() => {
            setRequestedAction(null);
          }}
        />
      )}
      {requestedAction === ProjectTypes.MenuActions.EXPORT && (
        <ExportModal
          assetId={story.id}
          onCloseRequest={() => {
            setRequestedAction(null);
          }}
          exporting="story"
        />
      )}
      {requestedAction === ProjectTypes.MenuActions.DELETE && (
        <DeleteConfirmationModal
          title="Delete story"
          message={`Are you sure you want to delete this story? All data will be permanently removed. This action cannot be undone.`}
          onConfirm={onDeleteStory}
          onCloseRequest={() => setRequestedAction(null)}
        />
      )}
    </>
  );
};

export default StoryMenu;
