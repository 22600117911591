import { DeleteConfirmationModal, Dropdowns, MUIcon } from '@platform/shared/ui';
import { PeTypes } from '@platform/types';
import React, { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';
import { useWorkspaceContext } from '../../contexts/WorkspaceContext';
import { WATCHLIST_DROPDOWN_OPTIONS } from '../../enums/dropdown';
import { generateLink } from '../../LinkGenerator';
import * as peApi from '../../pe.api';
import Thumb from '../Thumb';

interface Props {
  profile: PeTypes.Profile;
}

const ProfileWatchlistItem: React.FC<Props> = ({ profile }) => {
  const { id, title, watched, projectTitle } = profile;
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState<boolean>(false);
  const { activeWorkspace } = useWorkspaceContext();
  const queryClient = useQueryClient();
  const profileMutation = useMutation({
    mutationFn: () => peApi.watchProfile(id, !watched),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['profiles'] });
    },
  });

  const onSelectDropdownOption = (opt: Dropdowns.DropdownOption) => {
    switch (opt.value) {
      case 'DELETE':
        setShowDeleteConfirmationModal(true);
        break;
      case 'RENAME': // TODO implement
      case 'UNWATCH':
        profileMutation.mutate();
        break;
      default:
        return;
    }
  };

  const deleteProfileMutation = useMutation({
    mutationFn: async () => peApi.softDeleteProfile(profile.id),
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: ['profiles'] });
    },
  });

  const onDeleteProfile = () => {
    deleteProfileMutation.mutate();
    setShowDeleteConfirmationModal(false);
  };

  return (
    <div className="group inline-flex h-fit w-72 min-w-[24.53%] max-w-[300px] flex-col items-start justify-start">
      <Link
        to={generateLink('map', { workspaceId: activeWorkspace.id, mapId: id, mode: 'edit' })}
        className="flex w-full flex-col rounded-[16px] bg-white shadow hover:shadow-lg"
      >
        <div className="relative">
          <Thumb url={profile.thumbPath ?? ''} className={'h-[180px] w-full rounded-t-[16px] rounded-b-none'} />
          <div className="absolute top-5 right-6 flex h-8 items-center justify-center gap-1">
            <div className="mr-auto flex h-full" onClick={(e) => e.preventDefault()}>
              <Dropdowns.Dropdown
                icon={<MUIcon name="more_horiz" className="text-gray-600" />}
                iconClose={null}
                triggerClasses={'rounded-full'}
                options={WATCHLIST_DROPDOWN_OPTIONS}
                onSelect={onSelectDropdownOption}
                hAlignment="right"
              />
              {showDeleteConfirmationModal && (
                <DeleteConfirmationModal
                  title="Delete map"
                  message={`Are you sure you want to delete this map? All data will be permanently removed. This action cannot be undone.`}
                  onConfirm={onDeleteProfile}
                  onCloseRequest={() => setShowDeleteConfirmationModal(false)}
                />
              )}
            </div>
          </div>
        </div>
        <div className="flex w-full flex-col items-start justify-start px-1">
          <div className="flex grow flex-col gap-1 px-3 py-2">
            <span className="truncate text-xs font-semibold text-gray-600">{projectTitle ?? 'Unsorted'}</span>
            <span className="truncate text-base font-semibold text-gray-900">{title}</span>
          </div>
        </div>
        <div className="group-hover:text-primary-600 my-4 flex justify-between px-4 text-sm font-semibold text-gray-600">
          <span className="flex">Open</span>
          <MUIcon name="arrow_forward" className="group-hover:text-primary-600 m-0 mr-2 flex h-5 w-5 text-gray-600" />
        </div>
      </Link>
    </div>
  );
};

export default ProfileWatchlistItem;
