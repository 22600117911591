import { Buttons, DeleteConfirmationModal, MUIcon } from '@platform/shared/ui';
import { Helpers } from '@platform/utils';
import pluralize from 'pluralize';
import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { RuleGroupType } from 'react-querybuilder';
import { ReactComponent as DatasetNotFoundSVG } from '../../../../assets/dataset-not-found.svg';
import { useDataset } from '../../../hooks';
import * as peApi from '../../../pe.api';
import DownloadCsvButton from '../../DownloadCsvButton';
import { Loader } from '../../shared';
import ErrorPage from '../../shared/ErrorPage';
import DatasetTiles from '../DatasetTiles';
import DatasetHeader from './DatasetHeader';
import DatasetTable from './DatasetTable';
import FilterableVariablesPanel from './FilterableVariablesPanel';
import DatasetFilter from './Filtering';
import { ModeType } from './index';
import MoreDatasetOptionsButton from './MoreDatasetOptionsButton';

interface Props {
  datasetId: string;
  mode: ModeType;
}

enum ACTION {
  DATASET_FILTER,
  COLUMN_META,
}

const DEFAULT_FILTER = { combinator: 'and', rules: [] };

const Dataset: React.FC<Props> = ({ datasetId, mode }) => {
  const [currentAction, setCurrentAction] = useState<ACTION | undefined>(undefined);
  const [currentDataFilter, setCurrentDataFilter] = useState<RuleGroupType>(DEFAULT_FILTER);
  const [dataSize, setDataSize] = useState<[number, number]>([0, 0]);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);

  const datasetQuery = useDataset(datasetId);

  useEffect(() => {
    setCurrentAction(undefined);
    setCurrentDataFilter(DEFAULT_FILTER);
  }, [datasetId]);

  const deleteDatasetRowsMutation = useMutation({
    mutationFn: () => peApi.deleteRows(datasetId, selectedRows),
    onSuccess: async () => {
      setSelectedRows([]);
      setShowDeleteConfirmationModal(false);
    },
  });

  if (datasetQuery.isLoading || datasetQuery.isIdle) return <Loader />;
  if (datasetQuery.isError) {
    return (
      <ErrorPage
        imageComponent={<DatasetNotFoundSVG />}
        title="Dataset not found."
        message="The dataset you are trying to open does not exist or has been deleted."
      />
    );
  }

  const handleDataFilterUpdate = (newFilter: RuleGroupType) => setCurrentDataFilter(newFilter);

  const dataset = datasetQuery.data;
  const countOfValidFilters = currentDataFilter.rules.filter((rule) => Helpers.checkIfValidRule(rule, null)).length;
  const filterButtonLabel = countOfValidFilters ? `Filter (${countOfValidFilters})` : 'Filter';

  const tableMenu = (
    <div className="bg-primary-600 absolute top-0 left-0 right-0 z-[20] rounded-t-lg px-3">
      <div className="flex h-[49px] items-center gap-8 px-4 text-white">
        <button className="h-5" onClick={() => setSelectedRows([])}>
          <MUIcon name="close" />
        </button>
        <div className="text-sm text-gray-50">
          {selectedRows?.length} {pluralize('row', selectedRows?.length)} selected
        </div>
        <button className="h-5" onClick={() => setShowDeleteConfirmationModal(true)}>
          <MUIcon name="delete" />
        </button>
      </div>
    </div>
  );

  return (
    <div className="flex h-full w-full gap-2">
      <div className="flex min-w-0 grow flex-col">
        <DatasetHeader
          dataset={dataset}
          mode={mode}
          actions={
            <>
              <DownloadCsvButton datasetId={datasetId} hasFilters={countOfValidFilters > 0} />
              {currentAction == null && (
                <Buttons.Secondary
                  icon={<MUIcon name="tune" />}
                  className="rounded-full px-4"
                  onClick={() => setCurrentAction(ACTION.DATASET_FILTER)}
                >
                  {filterButtonLabel}
                </Buttons.Secondary>
              )}
              <MoreDatasetOptionsButton
                dataset={dataset}
                onShowFilterableVars={() => setCurrentAction(ACTION.COLUMN_META)}
              />
            </>
          }
        />
        {mode === 'map' ? (
          <DatasetTiles dataset={dataset} filter={currentDataFilter} />
        ) : (
          <DatasetTable
            datasetId={datasetId}
            filter={currentDataFilter}
            onRowSelection={setSelectedRows}
            selectedRows={selectedRows}
            onDataSizeChange={(r, c) => setDataSize([r, c])}
            tableMenu={tableMenu}
          />
        )}
      </div>
      <div className="flex shrink-0">
        {currentAction === ACTION.DATASET_FILTER && (
          <DatasetFilter
            dataset={dataset}
            filter={currentDataFilter}
            disabled={dataSize[0] * dataSize[1] <= 0}
            onCloseRequest={() => setCurrentAction(undefined)}
            onChange={handleDataFilterUpdate}
          />
        )}
        {currentAction === ACTION.COLUMN_META && (
          <div className="flex shrink-0">
            <FilterableVariablesPanel dataset={dataset} onCloseRequest={() => setCurrentAction(undefined)} />
          </div>
        )}
      </div>
      {showDeleteConfirmationModal && (
        <DeleteConfirmationModal
          title="Delete rows"
          message={`Are you sure you want to delete selected rows? All data will be permanently removed. This action cannot be undone.`}
          onConfirm={() => deleteDatasetRowsMutation.mutate()}
          onCloseRequest={() => setShowDeleteConfirmationModal(false)}
        />
      )}
    </div>
  );
};

export default Dataset;
