import { AbacusTypes } from '@platform/types';
import { useQuery } from 'react-query';

interface Props {
  datasetId: string;
  request: AbacusTypes.SummarizeRequest;
  api: (
    datasetId: string,
    request: AbacusTypes.SummarizeRequest,
    identifier?: string
  ) => Promise<AbacusTypes.SummarizeResult>;
  identifier?: string; // provides an easy way to identify different API calls by adding given identifier as query param
}

const useAbacusSummarization = ({ api, request, datasetId, identifier }: Props) =>
  useQuery(['abacus-summary', datasetId, request], () => api(datasetId, request, identifier));

export default useAbacusSummarization;
