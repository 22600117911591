import classNames from 'classnames';
import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';
import MUIcon from '../MUIcon';

interface DrawerProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  side?: 'top' | 'bottom' | 'left' | 'right';
  closeButton?: boolean;
  className?: string;
}

const Drawer: React.FC<DrawerProps> = ({
  isOpen,
  onClose,
  children,
  side = 'bottom',
  closeButton = true,
  className,
}) => {
  const [numDrawers, setNumDrawers] = React.useState(1);
  useEffect(() => {
    setNumDrawers(document.body.querySelectorAll('.drawer').length + 1);
  }, []);
  return createPortal(
    <>
      {/* Overlay */}
      <div
        className={classNames(
          `fixed inset-0 h-full w-full bg-black bg-opacity-70 transition-opacity duration-300 ease-in-out`,
          {
            'opacity-100': isOpen,
            'pointer-events-none opacity-0': !isOpen,
          }
        )}
        style={{
          zIndex: 100 * numDrawers,
        }}
        onClick={onClose}
      >
        {closeButton && (
          <div className="relative h-10 w-full bg-transparent">
            <button
              className="absolute top-3 right-3 text-gray-600 transition-opacity duration-300 ease-in-out hover:text-gray-800"
              onClick={onClose}
            >
              <MUIcon name="close" className="h-6 w-6 text-white" />
            </button>
          </div>
        )}
      </div>
      {/* Drawer */}
      <div
        className={classNames(
          `drawer fixed  bg-white shadow-lg transition-transform duration-300 ease-in-out`,
          {
            'top-0 left-0 right-0': side === 'top',
            'bottom-0 left-0 right-0': side === 'bottom',
            'top-0 left-0 bottom-0': side === 'left',
            'top-0 right-0 bottom-0': side === 'right',
          },
          {
            'top-10': side === 'bottom' && closeButton,
          },
          className
        )}
        style={{
          transform: isOpen
            ? 'translate(0, 0)' // Slide in
            : side === 'top'
            ? 'translateY(-100%)' // Slide out from top
            : side === 'bottom'
            ? 'translateY(100%)' // Slide out from bottom
            : side === 'left'
            ? 'translateX(-100%)' // Slide out from left
            : 'translateX(100%)', // Slide out from right
          zIndex: 100 * numDrawers + numDrawers,
        }}
      >
        {isOpen && children}
      </div>
    </>,
    document.body
  );
};

export default Drawer;
